<template>
	<button class="go-up" @click="goUp">
		<img alt="Go Up" src="~/assets/icons/go_up.svg" loading="lazy" width="16" height="16">
	</button>
</template>

<script setup>
function goUp() {
	window.scrollTo({ top: 0, behavior: 'smooth' });
}

</script>

<style lang="scss" scoped>
.go-up {
  position: fixed;
  right: 1em;
  bottom: 1em;

  width: 50px;
  height: 50px;

  background: rgba(0, 0, 0, 0.7);
  border: 0;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 50%;
    height: 50%;
  }
}
</style>
